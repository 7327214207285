<template>
  <div>
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="solution-header-container" :style="'background: url('+bannerBg+')'">
      <div class="solution-header-text">
        <h1 data-caption-delay="0" class="wow fadeInLeft">NXP解决方案及产品介绍</h1>
        <p data-caption-delay="100" class="wow fadeInRight">全球嵌入式应用安全连结解决方案的领导者</p>
        <div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)">
          <div>立即咨询</div>
        </div>
      </div>
    </div>
    <div class="sw-tab-title" ref="tab" :class="tabFixed ? getTabFixedClass() :''">
      <div class="container">
        <el-tabs v-model="activeProduct" @tab-click="handleTabClick">
          <el-tab-pane label="安全互联汽车解决方案" name="1">安全互联汽车解决方案</el-tab-pane>
          <el-tab-pane label="汽车电子产品" name="2">汽车电子产品</el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div :class="tabFixed?'solution-tab-fixed':''">
      <div v-if="activeProduct === '1'">
        <section class="solution-intros wow fadeInLeft">
          <div class="container">
            <el-row>
              <el-col :span="24">
                <div class="normal-title full-width">关于NXP</div>
                <div class="all-intro">
                  <div class="intro-detail">
                    <p>
                      NXP作为全球嵌入式应用安全连结解决方案的领导者，拥有60余年的专业技术经验，在全球30个国家设有业务机构。致力于通过创新为人们更智慧、安全和可持续的生活保驾护航。在推动安全互联汽车、工业与物联网、移动设备以及通信基础设施市场各类创新上起到关键性的作用。基于域的架构，NXP通过智能将汽车感知、思考和行动的功能组合在一起，以管理复杂性并分离与安全性、可升级性和功能安全性有关的问题，打造安全自动驾驶汽车的明确、精简的方式。为当今需要功能安全和信息安全的车辆提供可应对复杂自动驾驶、连接和电气化挑战的解决方案。
                    </p>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </section>
        <section class="vehicle-solution-sections solution-functions wow fadeInRight">
          <div class="container">
            <div class="normal-title full-width">ADAS和高度自动驾驶 <span>能够实现感测和思考的能力，同时确保车辆的安全运行</span></div>
            <div class="all-function">
              <ul class="priority-list">
                <li><span class="width140">雷达</span>NXP提供全系列可扩展雷达解决方案，包括高度集成的雷达MCU和收发器技术，满足USRR, SRR,
                  MRR和LRR等当前和未来的雷达应用需求
                </li>
                <li><span class="width140">视觉</span>S32V视觉处理器提供了视觉系统应用所需的性能和功能</li>
                <li><span class="width140">汽车高性能计算</span>融合了从多个传感器收集的车辆数据，可更准确地感知车辆周围的环境，从而做出更好、更安全的决策</li>
                <li><span class="width140">V2X</span>简化测试操作、降低测试成本、规范测试流程</li>
              </ul>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections wow fadeInLeft">
          <div class="container">
            <div class="normal-title mini-title full-width">车载信息娱乐系统与驾驶体验 <span>可提供高性能和灵活性的解决方案，实现丰富的用户体验</span></div>
            <div class="all-function">
              <ul class="priority-list">
                <li><span class="width140">车载信息娱乐系统</span>采用经过验证的车载信息娱乐系统解决方案，加快产品上市时间，满足快速变化的需求</li>
                <li><span class="width140">仪表板</span>完整的全集成仪表板解决方案——从入门级到高性能仪表板</li>
              </ul>
            </div>
          </div>
        </section>

        <section class="vehicle-solution-sections wow fadeInLeft">
          <div class="container">
            <div class="normal-title full-width">汽车连接 <span>多个无线接口可将汽车与外界无缝连接，支持汽车操作和车对车通信的各种功能</span></div>
            <div class="all-function">
              <ul class="priority-list">
                <li><span class="width140">V2X通信</span>与所有领先供应商的全球软件协议兼容，为有源安全系统和智能运输系统（ITS）管理提供V2X通信的完整解决方案</li>
                <li><span class="width140">汽车网络处理 (VNP)</span>VNP确保服务型网关的安全数据处理和联网，以支持无线（OTA）更新</li>
                <li><span class="width140">智能汽车门禁系统</span>恩智浦连接解决方案，例如超宽带（UWB）、Bluetooth®（BLE）、近场通信（NFC）和安全元件（SE），可实现真正安全的免手动智能<br/>汽车门禁
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section class="vehicle-solution-sections wow fadeInLeft">
          <div class="container">
            <div class="normal-title full-width">车身与舒适 <span>多功能和自适应汽车功能，确保车上驾乘人员的安全和舒适</span></div>
            <div class="all-function">
              <ul class="priority-list">
                <li>电泵、电机控制和辅助设备</li>
                <li>胎压监测系统</li>
              </ul>
            </div>
          </div>
        </section>

        <section class="vehicle-solution-sections wow fadeInLeft">
          <div class="container">
            <div class="normal-title full-width">动力与传动 <span>利用稳健而安全的汽车级解决方案，提高燃油经济性和性能</span></div>
            <div class="all-function">
              <ul class="priority-list">
                <li>电气化解决方案：HEV/EV电源逆变器和电池管理系统</li>
                <li>动力</li>
                <li>传动</li>
              </ul>
            </div>
          </div>
        </section>

        <section class="vehicle-solution-sections wow fadeInLeft">
          <div class="container">
            <div class="normal-title full-width">汽车网络 <span>汽车网络解决方案可实现车内连接以及与云端的安全连接</span></div>
            <div class="all-function">
              <ul class="priority-list">
                <li><span class="width140">汽车网络处理 (VNP)</span>可以安全地互连各种汽车网络，并支持无线（OTA）更新，提升功能安全、信息安全及乘客体验</li>
                <li><span class="width140">汽车E/E架构</span></li>
                <li><span class="width140">电动汽车充电桩</span>恩智浦解决方案具有精确的功率测量、设备管理和较高的数据安全性，可满足高效和互联电动汽车充电系统的要求</li>
              </ul>
            </div>
          </div>
        </section>

        <section class="vehicle-solution-sections wow fadeInLeft">
          <div class="container">
            <div class="normal-title small-title full-width">功能安全和汽车信息安全
              <span>恩智浦的功能安全和信息安全计划有助于实现无人驾驶，助力汽车电子领域的创新</span></div>
            <div class="all-function">
              <ul class="priority-list">
                <li><span class="width140">功能安全</span>简化系统安全符合当前功能安全标准的复杂度</li>
                <li><span class="width140">汽车信息安全</span>可激活多层防御，应对复杂的系统攻击</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      <div v-if="activeProduct === '2'">
        <section class="vehicle-solution-sections wow fadeInLeft">
          <div class="container">
            <div class="normal-title full-width">处理器和微控制器</div>
            <div class="all-function">
              <ul class="priority-list">
                <li><span class="width140">基于Arm®内核的处理器</span>S32G汽车网络处理器、S32Z和S32E实时处理器、S32R雷达处理器、S32V视觉处理器、i.MX应用处理器
                </li>
                <li><span class="width140">基于Arm内核的MCU</span>S32K通用MCU、i.MX RT跨界MCU、Kinetis® EA MCU、MAC57D5xx MCU</li>
                <li><span class="width140">Power Architecture®微控制器</span>MPC-LS汽车网络处理芯片组、S32R雷达微控制器、MCP5xxx微控制器</li>
                <li><span class="width140">其他处理器</span>S12 MagniV®微控制器、S12和S12X微控制器、S08微控制器/单片机、数字信号控制器、数字信号处理器</li>
              </ul>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections wow fadeInLeft">
          <div class="container">
            <div class="normal-title full-width">音频和收音机</div>
            <div class="all-function">
              <ul class="priority-list">
                <li>模拟/数字无线和音频</li>
                <li>汽车音频放大器</li>
              </ul>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections wow fadeInLeft">
          <div class="container">
            <div class="normal-title full-width">驾驶员辅助收发器</div>
            <div class="all-function">
              <ul class="priority-list">
                <li><span>雷达收发器产品</span>TEF82xx RFCMOS雷达收发器、TEF810x RFCMOS雷达收发器</li>
                <li><span>雷达SoC</span>SAF85xx RFCMOS汽车雷达SoC</li>
              </ul>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections wow fadeInLeft">
          <div class="container">
            <div class="normal-title full-width">车载网络</div>
            <div class="all-function">
              <ul class="priority-list">
                <li><span class="width140">CAN收发器</span>CAN和CAN FD产品组合涵盖了所有CAN功能和电源模式，提供较高的EMC性能、高品质和多源的工业基础</li>
                <li><span class="width140">以太网</span>汽车以太网交换机、汽车以太网PHY</li>
                <li><span class="width140">汽车LIN解决方案</span>恩智浦LIN解决方案提供了完整的多功能LIN汽车产品系列，包括单通道、双通道和四通道收发器和LIN迷你系统基础芯片（单通道和<br>多通道LIN收发器、LIN迷你SBC）
                </li>
                <li><span class="width140">FlexRay收发器</span>具有丰富的功能集和应用支持。产品组合包括：TJA1080A、TJA1081B、TJA1081GL、TJA1083G、TJA1085、TJA1085G、TJA1086G
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections wow fadeInLeft">
          <div class="container">
            <div class="normal-title full-width">外设与逻辑</div>
            <div class="all-function">
              <ul class="priority-list">
                <li><span class="width140">汽车LCD驱动器</span>提供较高可靠性和光学体验，同时支持广泛的分辨率（从4x32到4x160分段），提供封装及玻璃芯片版本</li>
                <li><span class="width140">LCD字符驱动器</span>具有更少的外部元件和更低的电流消耗，通过片上字符发生器ROM显示5 x 8点格式的一些字符行，可支持任何LCD点阵</li>
                <li><span class="width140">LCD图形驱动器</span>专为点阵图形显示而设计，支持大量图标和一系列7或14段的数字</li>
                <li><span class="width140">LCD段式驱动器</span>具有出色的大显示屏和大图标驱动能力，支持任何静态或多路复用的LCD，其中包含多达一定数量的段或图标</li>
              </ul>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections wow fadeInLeft">
          <div class="container">
            <div class="normal-title full-width">电源管理</div>
            <div class="all-function">
              <ul class="priority-list">
                <li><span class="width140">照明驱动器和控制器IC</span>汽车LED驱动器IC、HID控制器、I2C LED控制器、所有照明驱动器和控制器IC</li>
                <li><span class="width140">电池管理</span>电芯控制器、电池充电器、电池充电器、通用BMS通信TPL收发器和CAN FD网关、所有电池管理</li>
                <li><span class="width140">电机和螺线管驱动器</span>BLDC、H桥和步进电机、高电流电机控制、动力系统和引擎控制、所有电机和螺线管驱动器</li>
                <li><span class="width140">智能开关和驱动器</span>高边开关、低边开关、可配置开关、预驱动器、制动系统、安全气囊解决方案、无线电源</li>
                <li><span class="width140">PMIC和SBC</span>PMIC、微型SBC、安全SBC、所有PMIC和系统基础芯片</li>
              </ul>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections wow fadeInLeft">
          <div class="container">
            <div class="normal-title full-width">安全与认证</div>
            <div class="all-function">
              <ul class="priority-list">
                <li><span class="width140">汽车安全门禁</span>提供大量下一代汽车应答器和读卡器，打造门禁和防盗系统。产品组合：MIFARE®DESFire®EV3、NCF3340AHN、NCF3340AHN、<br>NCK2983AHN、NCx3320、NCx3321、OL2300NHN、OL2311AHN、NCx3310、NCJ37x、NCJ38A
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections wow fadeInLeft">
          <div class="container">
            <div class="normal-title full-width">传感器</div>
            <div class="all-function">
              <ul class="priority-list">
                <li><span class="width140">加速传感器</span>提供基于微机电系统(MEMS) 的差异化加速度传感器产品组合</li>
                <li><span class="width140">磁力传感器</span>提供广泛的磁阻传感器产品组合，特色磁传感器产品：NMH1000超低功耗单片霍尔效应磁场传感器、KMA220高精度双通道磁角度传感器、<br>KMA221磁角度传感器模块、KMZ41灵敏磁场传感器
                </li>
                <li><span class="width140">压力传感器</span>汽车安全压力传感器、电池压力监测传感器、大气压力：15至150kPa、差压/表压：高达10kPa、差压/表压：高达115kPa、差压/表压：<br>高达1000kPa、LPG和CNG
                  Gas：20至550kPa、胎压监测传感器、真空传感器：-50至-115kPa
                </li>
                <li><span class="width140">加速传感器</span>提供基于微机电系统(MEMS) 的差异化加速度传感器产品组合</li>
                <li><span class="width140">芯片温度传感器</span></li>
              </ul>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections wow fadeInLeft">
          <div class="container">
            <div class="normal-title full-width">无线</div>
            <div class="all-function">
              <ul class="priority-list">
                <li><span class="width140">DSRC安全调制解调器</span>V2X
                  DSRC/802.11p版本提供最短的实时通信延迟和超过1英里的工作范围，DSRC还提供专用的安全通道操作，使安全消息和其他数据<br>能够实时安全地传递
                </li>
                <li><span class="width140">低耗蓝牙(BLE)</span>Bluetooth Smart (低功耗蓝牙)解决方案包括高度集成的片上系统，具有大量闪存和SRAM内存，并采用Arm®
                  Cortex®-M内核
                </li>
                <li><span class="width140">汽车用Wi-Fi® + 蓝牙</span>致力于在多个市场大规模部署各类功率成本优化的Wi-Fi和蓝牙芯片组，使产品具备当今一流的Wi-Fi功能</li>
                <li><span class="width140">安全超宽带(UWB)</span>UWB技术实现了安全测距和精确感测，为无线设备创造了新的空间环境</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
    <bzInfo style="margin-top: 0"/>
    <main-footer></main-footer>
  </div>
</template>

<script>
import mainNav from "@/components/MainNav.vue";
import mainFooter from "@/components/MainFooter.vue";
import blueBg from "@/assets/images/solution-eletric-function-bg.png";
import bzInfo from "@/components/BzInfo.vue";
import bannerBg from '@/assets/images/banner-product.jpg';

export default {
  name: "NXP",
  components: {
    mainFooter,
    mainNav,
    bzInfo
  },
  data() {
    return {
      tabFixed: false,
      activeIndex: '3-5',
      blueBg: blueBg,
      bannerBg: bannerBg,
      activeProduct: '1',
      chatUrl: "https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
    }
  },
  mounted() {
    document.title = "NXP解决方案及产品介绍 - 解决方案 - 东信创智"
    new this.$wow.WOW().init()
    window.addEventListener("scroll", this.handleScroll)
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    linkUrl(url) {
      window.open(url, '_blank') // 在新窗口打开外链接
      // window.location.href =this.indexro;  //在本页面打开外部链接
    },
    handleScroll() {
      if (document.documentElement.clientWidth > 1100) {
        let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 70
        if (top < 0 && top > -630) {
          this.tabFixed = !this.tabFixed;
        } else if (document.documentElement.scrollTop > 630) {
          this.tabFixed = true;
        }
      } else {
        let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 50
        if (top < 0 && top > -250) {
          this.tabFixed = !this.tabFixed;
        } else if (document.documentElement.scrollTop > 250) {
          this.tabFixed = true;
        }
      }
    },
    getTabFixedClass() {
      if (document.documentElement.clientWidth < 1100) {
        return 'sw-tab-title-mobile-fixed'
      } else {
        return 'sw-tab-title-fixed'
      }
    },
    handleTabClick() {
      if (document.documentElement.clientWidth > 1100 && document.documentElement.scrollTop > 630) {
        document.documentElement.scrollTop = 630
      } else if (document.documentElement.clientWidth < 1100 && document.documentElement.scrollTop > 250) {
        document.documentElement.scrollTop = 250
      }
    }
  }
}
</script>
